<template>
  <div class="siteeditor">
    <!-- 导航栏 -->
    <el-header class="zg--header--main">
        <img class="zg--header--logo" src="https://image.qll-times.com/2020/05/d3c842b2adb3438ea13ff55c43689201.jpg">
        <label class="zg--header--title">商机圈子平台</label>
        <div class="zg--header--button">
          <el-button plain @click="addCaseDialog=true">更换模板</el-button>
          <el-button @click="saveConfigs" type="primary">保存</el-button>
        </div>        
    </el-header>

    <!-- 组件列表 -->
    <div class="scroll-none-custom left">
      <div class="basic">
        <h3>模块组件</h3>
        <div v-for="item in ModularList" :key="item.type" class="item" 
          @click="addComponents(item.type)"
          :class="{ 'item-noclick': configs.children.findIndex(e => e.comp == item.comp) != -1 }">
          <i class="zgcms" v-html="item.icon"></i>
          <p>{{ item.name }}</p>
        </div>
      </div>
      <div class="modular">
        <h3>基本组件</h3>
        <div v-for="item in BasicList" :key="item.type"
          @click="addComponents(item.type)"
          class="item">
          <i class="zgcms" v-html="item.icon"></i>
          <p>{{ item.name }}</p>
        </div>
      </div>
    </div>

    <!-- 组件展示页面 -->
    <div v-if="contentShow" class="center custom-scroll-bar" id="zgcmsCenter">
      <div class="show-area" id="zgcmsShowArea">
        <composite :configs="configs" :editModel="true"></composite>
      </div>
    </div>

    <!-- 组件详情 -->
    <div class="right">
      <component ref="editor" :is="selectedComponent.comp" :configs="selectedComponent"></component>
    </div>

    <!-- 选择案例模板弹窗 -->
    <el-dialog title="选择案例模板" width="600px" :visible.sync="addCaseDialog">
      <div class="case-template">
        <div class="case-template__item"
          v-for="item in templates" :key="item.id" @click="caseTemplateType=item.id">
          <div class="item" :class="{'item-active': caseTemplateType == item.id}">
            <img :src="item.logo">
          </div>
          <p class="title">{{item.name}}</p>
        </div>
      </div>

      <!-- 操作区 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="changeTemplate">使用</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  ZGcms, Component, Title, Text, Picture, Swipe, Video, PlaceHolder, BaseInfo, Goods, RichText, Case, Tags
} from '../../js/model'
import Header from '../../components/Header'
import Composite from '../../components/displays/Composite'
import EditorComponents from '../../components/editors'
import CaseTemplates from '@/websites/cms/js/template/Case'
export default {
  data(){
    return {
      BasicList: [ // 基本组件
        { type: 1, name: '标题', icon: '&#xe61f;' },
        { type: 2, name: '文本', icon: '&#xe620;' },
        { type: 3, name: '图片', icon: '&#xe665;' },
        { type: 4, name: '视频', icon: '&#xe667;' },
        { type: 5, name: '占位符', icon: '&#xe6c8;' },
        { type: 6, name: '图文组件', icon: '&#xe625;' }
      ],
      ModularList: [ // 模块组件
        { type: 101, name: '基本信息', icon: '&#xe626;', comp: 'z-baseinfo' },
        { type: 102, name: '关联商品', icon: '&#xe626;', comp: 'z-goods' },
        { type: 103, name: '相关案例', icon: '&#xe626;', comp: 'z-case' },
        { type: 104, name: '标签', icon: '&#xe626;', comp: 'z-tags' }
      ],
      templates: [
        { id: 0, name: "模板一", logo: "https://img0.qll-times.com/cdn/zgimg/case-template-1.png" },
        { id: 1, name: "模板二", logo: "https://img0.qll-times.com/cdn/zgimg/case-template-2.png" },
        { id: 2, name: "模板三", logo: "https://img0.qll-times.com/cdn/zgimg/case-template-3.png" },
      ],
      configs: new ZGcms({
        alias: "企业案例",
        logo: "",
        configs: {},
        children: [],
        comp: 'case'
      }),
      selectedComponent: {}, // 选中的组件数据
      contentShow: false, // 是否显示预览页面
      addCaseDialog: false, // 新增案例弹窗
      caseTemplateType: 0, // 模板类型
    }
  },
  created() {
    // 查询案例配置
    if(this.$route.query.id) this.getConfigs(this.$route.query.id);
  },
  mounted() {
    // 监听组件选中事件
    this.$EventBus.$on("selectComponent", e => this.selectComponentsPageScroll(e));

    // 如果是新增案例，则创建一个案例基本信息组件
    if(!this.$route.query.id) this.addComponents(101);
    this.contentShow = true;
  },
  methods: {
    // 查询案例配置
    async getConfigs(id){
      let res = await this.$ZGManager.getMaterial(id);
      if(res.status != 200) return;
      this.configs = JSON.parse(res.data.configs);
      this.contentShow = true;
      this.selectComponentsPageScroll(this.configs.children[this.configs.children.length - 1]);
    },

    // 保存案例配置
    async saveConfigs(){
      if(this.checkConfigs() != "pass") return this.$message.error(this.checkConfigs());
      let res;
      let index = this.configs.children.findIndex(e => e.comp == "z-baseinfo");
      let title = this.configs.children[index].alias;
      this.configs.logo = this.configs.children[index].logo;
      let configs = JSON.parse(JSON.stringify(this.configs));
      configs.children.map(e => delete e.checked);
      if(this.$route.query.id){
        res = await this.$ZGManager.updateMaterial(this.$route.query.id, {
          type: 9,
          title: title,
          configs: JSON.stringify(configs)
        });
        if(res.status != 200) return;
        this.$message.success("修改成功");
      }else{
        res = await this.$ZGManager.addMaterial({
          type: 9,
          title: title,
          configs: JSON.stringify(configs)
        });
        if(res.status != 200) return;
        this.$router.replace({ query: { id: res.data.id } });
        this.$message.success("创建成功");
      }
    },
    
    // 更换模板
    async changeTemplate(){
      let res = await this.$confirm('此操作将丢失当前的全部内容, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      });
      if(res != "confirm") return;
      this.configs = CaseTemplates[this.caseTemplateType];
      this.addCaseDialog = false;
      this.$EventBus.$emit('createComponent');
    },

    // 检查案例配置是否完整
    checkConfigs(){
      let temp = "pass";

      // 检查案例标题、封面是否完整
      let index = this.configs.children.findIndex(e => e.comp == "z-baseinfo");
      if(this.configs.children[index].alias.length < 1) return temp = "案例标题不能为空";
      if(!this.configs.children[index].logo) return temp = "案例封面不能为空";

      return temp;
    },

    // 添加组件
    addComponents(type) {
      switch (type) {
        case 1:
          this.configs.children.push(new Title());
          break;
        case 2:
          this.configs.children.push(new Text());
          break;
        case 3:
          this.configs.children.push(new Picture());
          break;
        case 4:
          this.configs.children.push(new Video());
          break;
        case 5:
          this.configs.children.push(new PlaceHolder());
          break;
        case 6:
          this.configs.children.push(new RichText());
          break;
        case 101: 
          this.configs.children.push(new BaseInfo({
            alias: "案例名称",
            logo: "",
            desc: "案例描述",
            configs: {
              hideDel: true
            },
            comp: 'z-baseinfo'
          }));
          break;
        case 102: 
          this.configs.children.push(new Goods());
          break;
        case 103: 
          this.configs.children.push(new Case());
          break;
        case 104: 
          this.configs.children.push(new Tags());
          break;
        default:
          break;
      }
      // 发出创建组件的事件
      this.$EventBus.$emit('createComponent');
      
      // 启用组件数据改动监听
      if(this.configs.children.length <= 1){
        let timer = setTimeout(() => {
          this.$refs.editor.setValue();
        }, 200);
      }
    },

    // 选中组件页面滚动
    selectComponentsPageScroll(e){
      this.selectedComponent = e;
      // 如果选中的是最后一个组件，则移动页面到最底部
      if(e.id == this.configs.children[this.configs.children.length - 1].id){
        this.$nextTick(e => $("#zgcmsCenter").scrollTop($("#zgcmsShowArea").height()));
      }
    }
  },
  components: {
    ...EditorComponents,
    'composite': Composite,
    'zg-header': Header
  },
}
</script>

<style lang="scss" scoped>
.siteeditor{
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.left{
  width: 220px;
  height: calc(100% - 64px);
  background-color: #fff;
  overflow-x: hidden;
  overflow-y: auto;
  float: left;
  box-shadow: 1px 0 rgba(0, 0, 0, 0.1);
  h3{
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    font-weight: 550;
    border-bottom: 1px solid #e1e1e1;
  }
  .item{
    display: inline-block;
    width: 110px;
    height: 110px;
    border-right: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
    box-sizing: border-box;
    color: #666;
    &:hover{
      color: #2d7ee8;
      background-color: rgba(45,126,232,.1);
      border-color: rgba(45,126,232,.1);
    }
    &:nth-child(2n + 1){
      border-right: transparent;
    }
    i{
      display: inline-block;
      width: 100%;
      line-height: 70px;
      font-size: 30px;
      text-align: center;
    }
    p{
      text-align: center;
      font-size: 14px;
      line-height: 40px;
    }
  }
  .item-noclick{
    pointer-events: none;
    background-color: #d3d3d3;
  }
}
.center{
  width: calc(100% - 880px);
  height: calc(100% - 64px);
  overflow-x: hidden;
  overflow-y: auto;
  float: left;
  .show-area{
    width: 375px;
    min-height: 603px;
    margin: 0 auto;
    margin-top: 100px;
    margin-bottom: 80px;
    background-color: #fff;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.1);
  }
  @media screen and (max-width: 1450px) {
    .show-area{
      margin-top: 50px;
    }
  }
}
.right{
  width: 520px;
  height: calc(100% - 94px);
  background-color: #fff;
  padding: 10px;
  overflow-x: hidden;
  overflow-y: auto;
  float: right;
  padding: 15px;
}
.case-template{
  width: 100%;
  background-color: #f8f9fa;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  .case-template__item{
    height: 200px;
  }
  .item{
    width: 146px;
    height: 146px;
    border: 2px solid #fff;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ececec;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .item-active{
    border-color: #2d7ee8;
  }
  .image-text{
    background-image: url("https://img0.qll-times.com/cdn/zgimg/case-default-logo.png");
    background-size: 100%;
  }
  .title{
    line-height: 40px;
    text-align: center;
  }
}
</style>